<template>
  <div>

    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img :src="logoSrc" alt="Logo" height="100">
        </a>
        <h4>Randolph Car Wash</h4>
      </div>
    </nav>
    <!-- Hero Section with placeholder image -->
    <header class="jumbotron jumbotron-fluid bg-primary text-white">
      <div class="container">
        <h1 class="display-4 text-center">Welcome to Randolph Car Wash</h1>
        <p class="lead text-center">Clean, Shine, and Drive!</p>
        <p class="text-center"><strong>Location:</strong> 148 Kienow Dr, Randolph, WI 53956</p>
      </div>
    </header>

    <!-- Features Section -->
    <section class="container my-4">
      <h2 class="text-center mb-5">Our Features</h2>
      <div class="row">
        <!-- Feature Card -->
        <div class="col-md-4 mb-4" v-for="feature in features" :key="feature.title">
          <div class="card">
            <img :src="feature.imageSrc" :alt="feature.title" class="card-img-top">
            <div class="card-body">
              <h5 class="card-title">{{ feature.title }}</h5>
              <p class="card-text">{{ feature.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ... [Your Previous Code] ... -->

    <!-- Contact Section -->
    <section class="container my-5">
      <h2 class="text-center mb-5">Contact Us</h2>
      <div class="row">
        <div class="col-md-6 mb-4">
          <h4 class="mb-3">Send Us A Message</h4>
          <form method="POST" action="https://formspree.io/f/mleybbrz">
            <div class="mb-3">
              <label for="email" class="form-label">Email address</label>
              <input type="email" class="form-control" id="email" name="email" required>
            </div>
            <div class="mb-3">
              <label for="message" class="form-label">Message</label>
              <textarea class="form-control" id="message" name="message" rows="4" required></textarea>
            </div>
            <button type="submit" class="btn btn-primary">Send Message</button>
          </form>

        </div>
        <div class="col-md-6 mb-4">
          <h4 class="mb-3">Visit or Call Us</h4>
          <p><i class="fas fa-map-marker-alt"></i> 148 Kienow Dr, Randolph, WI 53956</p>
          <p><i class="fas fa-phone"></i> (920) 296-3999</p>
          <p><i class="fas fa-envelope"></i> craigvg@samswelldrilling.com</p>
          <p><i class="fas fa-clock"></i> Mon - Sun: 24 Hours</p>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <footer class="bg-dark text-white py-4">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h5>Randolph Car Wash</h5>
            <p>Cleaning cars in Randolph since 2000.</p>
          </div>
          <div class="col-md-6 text-md-right">
            <ul class="list-inline">
              <li class="list-inline-item"><a href="#" class="text-white">Privacy Policy</a></li>
              <li class="list-inline-item"><a href="#" class="text-white">Terms of Service</a></li>
            </ul>
          </div>
        </div>
        <div class="text-center mt-3">
          <p>&copy; 2023 Randolph Car Wash. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

import myImage from '@/assets/images/carWashLogo.png';
import image2 from '@/assets/images/carWashMain.jpg';
import image3 from '@/assets/images/manualBay.jpg';
import image4 from '@/assets/images/dryers.jpg';
import image5 from '@/assets/images/vacuums.jpg';
import image6 from '@/assets/images/creditCard.jpg';
import image7 from '@/assets/images/pricing.jpg';

export default {
  name: "FeatureCards",
  data() {
    return {
      logoSrc: myImage,
      features: [
        {
          imageSrc: image2,
          title: 'Automatic touchless car wash',
          description: 'Experience a touchless wash for a perfect clean.'
        },
        {
          imageSrc: image3,
          title: 'Two manual car wash bays',
          description: 'Wash your car yourself with our high pressure hoses.'
        },
        {
          imageSrc: image4,
          title: 'Automatic Dryers',
          description: 'Leave spot free with our automatic dryers.'
        },
        {
          imageSrc: image5,
          title: 'Self-serve Vacuums',
          description: 'Vacuum your car with our self-serve vacuums.'
        },
        {
          imageSrc: image6,
          title: 'Credit Card Payment',
          description: 'Conveniently pay with your credit card at our payment kiosk.'
        },
        {
          imageSrc: image7,
          title: 'Flexible Pricing',
          description: 'Whether you want a basic wash or the works, we have options for you.'
        }
      ]
    }
  }
};
</script>


<style scoped>
/* Custom styles if needed */

.footer a:hover {
  text-decoration: underline;
}
</style>